var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.slides && _vm.slides.length > 0)?_c('div',[_c('div',{staticClass:"example-3d"},[_c('swiper',{ref:"swiperTop",staticClass:"swiper gallery-top",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.slides),function(slide){return _c('swiper-slide',{key:slide.id,staticStyle:{"width":"80%","height":"100%"}},[(slide.imagine && slide.imagine.length > 0)?_c('img',{attrs:{"src":_vm.width > 1000
							? ("" + _vm.databaseLink + (slide.imagine[0].url))
							: _vm.width <= 1000 && _vm.width > 750
							? ("" + _vm.databaseLink + (slide.imagine[0].formats.large.url))
							: _vm.width <= 750 && _vm.width > 500
							? ("" + _vm.databaseLink + (slide.imagine[0].formats.medium.url))
							: _vm.width <= 500
							? ("" + _vm.databaseLink + (slide.imagine[0].formats.small.url))
							: null,"alt":slide.alternativeText}}):_c('div',{staticClass:"video-wrapper is-relative"},[_c('iframe',{staticClass:"youtube-video",attrs:{"src":slide.url,"frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})])])}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2)],1),_c('swiper',{ref:"swiperThumbs",staticClass:"swiper gallery-thumbs",attrs:{"options":_vm.swiperOptionThumbs}},_vm._l((_vm.slides),function(slide){return _c('swiper-slide',{key:slide.id},[(slide.imagine && slide.imagine.length > 0)?_c('img',{attrs:{"src":(_vm.databaseLink + "/" + (slide.imagine[0].formats.small.url)),"alt":slide.alternativeText}}):_c('iframe',{attrs:{"src":slide.url,"frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})])}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }