<template>
	<main class="product-page" v-if="product && product !== null">
		<section class="section back-section py-3">
			<div class="container">
				<button
					class="btn btn--secondary"
					title="Înapoi la pagina anterioară"
					aria-label="Înapoi la pagina anterioară"
					@click="$router.go(-1)"
				>
					Înapoi
				</button>
			</div>
		</section>
		<component-products-list-info
			v-if="checkProductInList"
			:title="product.titlu"
			:productsNumber="productsList.length"
		/>
		<div class="section section--larger">
			<div class="container">
				<div class="columns is-multiline is-vcentered">
					<div class="column is-12 is-7-desktop">
						<component-product-carousel
							v-if="product.carusel && product.carusel.length > 0"
							:slides="product.carusel"
							:width="width"
						></component-product-carousel>
						<div v-else class="product__image">
							<img
								:src="
									width > 1000
										? `${databaseLink}${product.imagine.url}`
										: width <= 1000 && width > 750
										? `${databaseLink}${product.imagine.formats.large.url}`
										: width <= 750 && width > 500
										? `${databaseLink}${product.imagine.formats.medium.url}`
										: width <= 500
										? `${databaseLink}${product.imagine.formats.small.url}`
										: null
								"
								:alt="product.titlu"
							/>
						</div>
					</div>
					<div class="column is-5-desktop">
						<div class="product">
							<h2
								class="product__title has-text-centered has-text-left-desktop mb-4"
							>
								{{ product.titlu }}
							</h2>
							<strong
								v-if="product.pret && product.pret > 0"
								class="product__price has-text-centered has-text-left-desktop is-block mb-4"
								>{{ product.pret }} RON</strong
							>
							<markdown-it-vue
								class="mb-4"
								:content="product.detalii"
							/>
							<button
								v-if="product.pret && product.pret > 0"
								class="btn btn--secondary mr-2 mb-2"
								@click="addProduct"
							>
								<span v-if="checkProductInList">
									Produs adăugat
								</span>
								<span v-else>
									Adaugă în listă
								</span>
							</button>
							<button
								v-if="product.pret && product.pret > 0"
								class="btn btn--primary"
								@click="openModalWithOneProduct"
							>
								Mă interesează
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="section section--larger">
			<div class="container">
				<div class="tabs is-boxed">
					<ul class="tabs__nav">
						<li :class="{ 'is-active': isActive('description') }">
							<a @click.prevent="setActiveTab('description')">
								<span>Descriere</span>
							</a>
						</li>
						<li :class="{ 'is-active': isActive('ingredients') }">
							<a @click.prevent="setActiveTab('ingredients')">
								<span>Ingrediente</span>
							</a>
						</li>
						<li :class="{ 'is-active': isActive('usage') }">
							<a @click.prevent="setActiveTab('usage')">
								<span>Mod de utilizare</span>
							</a>
						</li>
					</ul>
				</div>
				<div class="tabs__content is-relative">
					<markdown-it-vue
						class="tab"
						v-if="isActive('description')"
						:content="product.descriere"
					/>
					<markdown-it-vue
						class="tab"
						v-if="isActive('ingredients')"
						:content="product.ingrediente"
					/>
					<markdown-it-vue
						class="tab"
						v-if="isActive('usage')"
						:content="product.mod_de_utilizare"
					/>
				</div>
			</div>
		</div>
		<component-certificates
			v-if="product.certificates && product.certificates.length > 0"
			:bgGrey="false"
			:certificates="product.certificates"
		></component-certificates>
		<b-modal
			v-model="modalWithSingleProductSetterAndGetter"
			trap-focus
			:destroy-on-hide="true"
			aria-role="dialog"
			aria-modal
			@close="closeModalWithSingleProduct"
		>
			<template #default="props">
				<component-command-modal
					@close="props.close"
					:product="currentProduct"
				></component-command-modal>
			</template>
		</b-modal>
	</main>
</template>

<script>
	import ComponentProductCarousel from '@/components/ComponentProductCarousel.vue';
	import ComponentProductsListInfo from '@/components/ComponentProductsListInfo.vue';
	import ComponentCommandModal from '@/components/ComponentCommandModal.vue';

	import { mapActions, mapState } from 'vuex';

	export default {
		components: {
			ComponentProductCarousel,
			ComponentProductsListInfo,
			ComponentCommandModal,
		},
		created() {
			this.getProduct(this.productId);
		},
		data() {
			return {
				width: null,
				modalWithOneProduct: false,
				activeItem: 'description',
				productInList: null,
			};
		},
		computed: {
			databaseLink() {
				return process.env.VUE_APP_STRAPI_DATABASE_URL;
			},
			...mapState('product', [
				'product',
				'productsList',
				'currentProduct',
				'modalWithSingleProduct',
			]),
			productId() {
				let route = this.$route.path;
				return route.slice(route.lastIndexOf('-') + 1);
			},
			checkProductInList() {
				return this.productsList.find(
					(prod) => prod.id === this.product.id
				);
			},
			modalWithSingleProductSetterAndGetter: {
				set() {
					return this.$store.commit(
						'product/SET_MODAL_COMMAND_WITH_SINGLE_PRODUCT_STATUS',
						{ product: null, status: false }
					);
				},
				get() {
					return this.modalWithSingleProduct;
				},
			},
		},
		methods: {
			...mapActions('product', ['getProduct', 'addProductToList']),
			setActiveTab(item) {
				this.activeItem = item;
			},
			isActive(item) {
				return this.activeItem === item;
			},
			addProduct() {
				const product = {
					_routePath: this.$route.path,
					_cantitate: 1,
					_pret: this.product.pret,
					...this.product,
				};
				this.$store.commit('product/ADD_PRODUCT_TO_LIST', product);
				this.$store.commit(
					'product/SET_MODAL_PRODUCTS_LIST_STATUS',
					true
				);
			},
			openModalWithOneProduct() {
				const prod = {
					_routePath: this.$route.path,
					_cantitate: 1,
					_pret: this.product.pret,
					...this.product,
				};
				this.$store.commit(
					'product/SET_MODAL_COMMAND_WITH_SINGLE_PRODUCT_STATUS',
					{ product: prod, status: true }
				);
			},
			closeModalWithSingleProduct() {
				this.$store.commit(
					'product/SET_MODAL_COMMAND_WITH_SINGLE_PRODUCT_STATUS',
					{ product: null, status: false }
				);
			},
		},
	};
</script>

<style scoped lang="scss">
	.back-section {
		background: linear-gradient(
			to bottom,
			lighten($gray, 10%),
			lighten($gray, 40%)
		);
	}
	.product {
		height: 100%;
		&__title {
			font-size: 2rem;
			@media (min-width: 768px) {
				font-size: 2.5rem;
			}
			@media (min-width: 992px) {
				font-size: 2.75rem;
			}
		}
		&__price {
			color: lighten($primary, 20%);
			font-size: 1.25rem;
			font-weight: $semibold;
			@media (min-width: 768px) {
				font-size: 1.5rem;
			}
			@media (min-width: 992px) {
				font-size: 1.75rem;
			}
		}
		&__details {
			li::before {
				content: '';
				background-color: lighten($gray, 20%);
				border-radius: 50%;
				display: inline-block;
				margin-right: 0.5rem;
				width: 10px;
				height: 10px;
			}
		}
		@media (min-width: 1024px) {
			padding: 0 2rem;
		}
	}
	.tabs {
		li.is-active {
			a {
				color: lighten($primary, 20%);
			}
		}
	}
	.section {
		&__title {
			color: $primary;
			font-size: 1.25rem;
			letter-spacing: 1px;
			text-transform: uppercase;
			text-align: center;
			@media (min-width: 481px) {
				font-size: 1.5rem;
			}
			@media (min-width: 769px) {
				font-size: 2rem;
			}
		}
	}
</style>
